export default function DeleteEstablishment({
  navigate,
  id,
  token,
  setToken,
  setTokenError,
  renderCount,
  setRenderCount,
  setOpenUpdateModal,
  setDatabaseAlert
}) {

  return fetch('https://api.shrimp-republic.com/graphql', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query:
      `mutation {
        destroyEstablishment (
          input: {
            token: "${token}",
            id: ${id}
          }
        ) {
          establishment {
            id
            name
          }
          errors
        }
      }`
    })
  }, [])
  .then((response) => {
    if(response.ok) {
      setTokenError(false)
      var json = response.json()
      console.log("No error", json)
      return json
    } else {
      setTokenError(true)
      setToken("invalid")
      navigate("/")
      console.log("Delete error big time")
    }
  })
  .then((json) => {
    var responseData = json.data
    console.log("Response data", responseData)
    
    var responseObject = responseData.destroyEstablishment
    console.log("Response obj", responseObject)
    alert = "Destroyed Establishment: " + String(id)
    console.log("Alert check", alert)
    setDatabaseAlert(alert)
    setOpenUpdateModal(false)
    setRenderCount(renderCount + 1)
    return responseObject
  })
  .catch(error => {
    setTokenError(true)
    console.log("Invalid token", error)
  })
}