export default function CreateEstablishment({
  navigate,
  name,
  city,
  state,
  zipCode,
  lat,
  lon,
  classification,
  streetAddress,
  url,
  phone,
  description,
  hours,
  token,
  setToken,
  setTokenError,
  renderCount,
  setRenderCount,
  setOpenCreateModal,
  setDatabaseAlert
}) {

  return fetch('https://api.shrimp-republic.com/graphql', {
    method: "POST", 
    headers: {
        "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query:
      `mutation {
        createEstablishment (
          input: {
            token: "${token}",
            name: "${name}",
            city: "${city}",
            state: "${state}",
            zipCode: "${zipCode}",
            lat: "${lat}",
            lon: "${lon}",
            classification: "${classification}",
            streetAddress: "${streetAddress}",
            url: "${url}",
            phone: "${phone}",
            description: ${JSON.stringify(description)},
            hours: {
              Monday: "${hours["Monday"]}",
              Tuesday: "${hours["Tuesday"]}",
              Wednesday: "${hours["Wednesday"]}",
              Thursday: "${hours["Thursday"]}",
              Friday: "${hours["Friday"]}",
              Saturday: "${hours["Saturday"]}",
              Sunday: "${hours["Sunday"]}",
            },
          }) {
          establishment {
            id
            name
            streetAddress
            city
            state
            zipCode
            lat
            lon
            url
            phone
            description
            classification
          }
          errors
        }
      }`
    })
}, [])
  .then((response) => {
    if(response.ok) {
      setTokenError(false)
      var json = response.json()
      console.log("No error", json)
      return json
    } else {
      setTokenError(true)
      setToken("invalid")
      navigate("/")
      console.log("Create error big time")
    }
  })
  .then((json) => {
    var responseData = json.data
    console.log("Response data", responseData)

    var responseObject = responseData.createEstablishment
    console.log("Response obj", responseObject)
    alert = "Created New Establishment"
    console.log("The alert", alert)
    setDatabaseAlert(alert)
    setOpenCreateModal(false)
    setRenderCount(renderCount + 1)
    return responseObject
  })
  .catch(error => {
    setTokenError(true)
    console.log("Invalid token", error)
  })
}