import React from "react"
import styles from "../../styles/features/createModal.module.css"
import CreateForm from "./createForm.tsx";
import { NavigateFunction, useNavigate } from "react-router-dom";

function CreateModal({
  token,
  setToken,
  setTokenError,
  renderCount,
  setRenderCount,
  setOpenCreateModal,
  databaseAlert,
  setDatabaseAlert
}) {

  const navigate: NavigateFunction = useNavigate()

  return (
    <div className={styles.main}>
      <CreateForm
        navigate={navigate}
        token={token}
        setToken={setToken}
        setTokenError={setTokenError}
        renderCount={renderCount}
        setRenderCount={setRenderCount}
        setOpenCreateModal={setOpenCreateModal}
        databaseAlert={databaseAlert}
        setDatabaseAlert={setDatabaseAlert}
      />
    </div>
  )
}

export default CreateModal