export default function UpdateEstablishment({
  navigate,
  id,
  businessName,
  streetAddress,
  city,
  state,
  zipCode,
  lat,
  lon,
  url,
  phone,
  description,
  classification,
  hours,
  token,
  setToken,
  setTokenError,
  renderCount,
  setRenderCount,
  setOpenUpdateModal,
  setDatabaseAlert
}) {

  return fetch('https://api.shrimp-republic.com/graphql', {
    method: "POST", 
    headers: {
        "Content-Type": "application/json",
    },
    body: JSON.stringify({
        query: 
        `mutation {
          updateEstablishment(
            input: {
              token: "${token}",
              id: ${id},
              name: "${businessName}",
              streetAddress: "${streetAddress}",
              city: "${city}",
              state: "${state}",
              zipCode: "${zipCode}",
              lat: "${lat}",
              lon: "${lon}",
              url: "${url}",
              phone: "${phone}",
              description: ${JSON.stringify(description)},
              classification: "${classification}",
              hours: {
                Monday: "${hours["Monday"]}",
                Tuesday: "${hours["Tuesday"]}",
                Wednesday: "${hours["Wednesday"]}",
                Thursday: "${hours["Thursday"]}",
                Friday: "${hours["Friday"]}",
                Saturday: "${hours["Saturday"]}",
                Sunday: "${hours["Sunday"]}"
              },
            }) {
              establishment {
                id
                name
                streetAddress
                city
                state
                zipCode
                lat
                lon
                url
                phone
                description
                classification
                reviewed
              }
              errors
          }
        }`,
      }),
  }, [])
  .then((response) => {
    if(response.ok) {
      setTokenError(false)
      var json = response.json()
      console.log("No error", json)
      return json
    } else {
      setTokenError(true)
      setToken("invalid")
      navigate("/")
      console.log("Update error big time")
    }
  })
  .then((json) => {
    var responseData = json.data
    console.log("Response data", responseData)

    var responseObject = responseData.updateEstablishment
    console.log("Response obj", responseObject)
    alert = "Updated Establishment: " + String(id)
    console.log("The alert", alert)
    setDatabaseAlert(alert)
    setOpenUpdateModal(false)
    setRenderCount(renderCount + 1)
    return responseObject
  })
  .catch(error => {
    setTokenError(true)
    console.log("Invalid token", error)
  })
}