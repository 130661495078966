import React, { useState } from "react"
import styles from "../../styles/features/updateModal.module.css"
import { NavigateFunction, useNavigate } from "react-router-dom";
import UpdateForm from "./updateForm.tsx";
import Establishment from "../../types/establishment"

function UpdateModal({
  token,
  setToken,
  setTokenError,
  establishment,
  renderCount,
  setRenderCount,
  setOpenUpdateModal,
  databaseAlert,
  setDatabaseAlert
}) {
  const navigate: NavigateFunction = useNavigate()

  return (
    <div className={styles.main}>
      <UpdateForm 
        establishment={establishment} 
        navigate={navigate} 
        token={token}
        setToken={setToken}
        setTokenError={setTokenError}
        renderCount={renderCount}
        setRenderCount={setRenderCount}
        setOpenUpdateModal={setOpenUpdateModal}
        databaseAlert={databaseAlert}
        setDatabaseAlert={setDatabaseAlert}
      />
    </div>
  )
}

export default UpdateModal