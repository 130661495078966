import React, { useState, useEffect } from "react";
import styles from '../../styles/screens/dash.module.css'
import Header from "../features/header.tsx";
import Body from "../features/body.tsx";
import Modal from "../features/modal.tsx";
import FetchEstablishments from "../../helpers/apiCalls/establishments.js";
import Establishment from "../../types/establishment.tsx"
import { useNavigate } from "react-router-dom";
import FetchEstablishmentsByIdenticalLatAndLon from "../../helpers/apiCalls/establishmentsByIdenticalLatAndLon.js";
import UpdateModal from "../features/updateModal.tsx";
import CreateModal from "../features/createModal.tsx";

function Dash({
  token, 
  setToken,
  setTokenError
}) {
  const navigate = useNavigate()
  const [openModal, setOpenModal] = useState<Boolean>(false)
  const [openUpdateModal, setOpenUpdateModal] = useState<Boolean>(false)
  const [openCreateModal, setOpenCreateModal] = useState<Boolean>(false)
  const [establishment, setEstablishment] = useState<Establishment|null>(null)
  const [establishments, setEstablishments] = useState<Establishment[]>([])
  const [apiError, setApiError] = useState<Boolean>(false)
  const [renderCount, setRenderCount] = useState<Number>(0)
  const [databaseAlert, setDatabaseAlert] = useState("")

  const fetchDuplicates = () => {
    FetchEstablishmentsByIdenticalLatAndLon({
      navigate,
      setApiError,
      setEstablishments,
      setToken,
      setTokenError,
      token,
    })
    .then(() => {
      setTokenError(false)
    })
  }
  const clickEstablishment = (establishment: Establishment) => {
    setEstablishment(establishment)
    setOpenModal(false)
    setOpenUpdateModal(true)
  }

  useEffect(() => {
    if (token !== "invalid") {
      FetchEstablishments({
        navigate,
        setApiError,
        setEstablishments,
        setToken,
        setTokenError,
        token,
      })
      .then(() => {
        setTokenError(false)
      })
    } else {
      setTokenError(true)
      navigate("/")
    }
  }, [token, renderCount]);

  useEffect(() => {
    if (databaseAlert !== "") {
      setTimeout(() => {
        setDatabaseAlert("")
      }, 3000)
    }
  }, [databaseAlert])

  return (
    <div className={styles.main}>
      <Header 
        setOpenModal={setOpenModal} 
        fetchDuplicates={fetchDuplicates}
        databaseAlert={databaseAlert}
        setOpenCreateModal={setOpenCreateModal}
      />
      <Body 
        establishments={establishments} 
        openUpdateModal={openUpdateModal} 
        setOpenUpdateModal={setOpenUpdateModal} 
        setEstablishment={setEstablishment}
      />
      {openModal ?
      <Modal 
        setOpenModal={setOpenModal} 
        setEstablishments={setEstablishments} 
        token={token} 
        setToken={setToken} 
        setTokenError={setTokenError} 
        setApiError={setApiError}
      />
       : openUpdateModal ?
       <UpdateModal
          token={token}
          setToken={setToken}
          setTokenError={setTokenError}
          establishment={establishment}
          renderCount={renderCount}
          setRenderCount={setRenderCount}
          setOpenUpdateModal={setOpenUpdateModal}
          databaseAlert={databaseAlert}
          setDatabaseAlert={setDatabaseAlert}
        />
      : openCreateModal ?
      <CreateModal
        token={token}
        setToken={setToken}
        setTokenError={setTokenError}
        renderCount={renderCount}
        setRenderCount={setRenderCount}
        setOpenCreateModal={setOpenCreateModal}
        databaseAlert={databaseAlert}
        setDatabaseAlert={setDatabaseAlert}
      />
       : null
       }
    </div>
  )
}

export default Dash