import React, { useState } from "react"
import UpdateInputField from "./updateInputField.tsx"
import styles from "../../styles/features/updateForm.module.css"
import usStates from "../../helpers/data/usStates.js"
import classifications from "../../helpers/data/classifications.js"
import UpdateEstablishment from "../../helpers/apiCalls/updateEstablishment.js"
import DeleteEstablishment from "../../helpers/apiCalls/deleteEstablishment.js"
import NavButton from "./navButton.tsx"
import hoursOptions from "../../helpers/data/hoursOptions.js"
interface Day {
  title: string,
  setter: any,
  value: string
}

export default function UpdateForm({
  establishment,
  navigate,
  token,
  setToken,
  setTokenError,
  renderCount,
  setRenderCount,
  setOpenUpdateModal,
  databaseAlert,
  setDatabaseAlert
}) {

  const [businessName, setBusinessName] = useState(establishment.name)
  const [businessNameError, setBusinessNameError] = useState<Boolean>(false)
  const [streetAddress, setStreetAddress] = useState(establishment.streetAddress)
  const [streetAddressError, setStreetAddressError] = useState<Boolean>(false)
  const [city, setCity] = useState(establishment.city)
  const [cityError, setCityError] = useState<Boolean>(false)
  const [usState, setUsState] = useState(establishment.state)
  const [zipCode, setZipCode] = useState(establishment.zipCode)
  const [zipCodeError, setZipCodeError] = useState<Boolean>(false)
  const [lat, setLat] = useState(establishment.lat)
  const [latError, setLatError] = useState<Boolean>(false)
  const [lon, setLon] = useState(establishment.lon)
  const [lonError, setLonError] = useState<Boolean>(false)
  const [url, setUrl] = useState(establishment.url)
  const [urlError, setUrlError] = useState<Boolean>(false)
  const [phone, setPhone] = useState(establishment.phone)
  const [phoneError, setPhoneError] = useState<Boolean>(false)
  const [description, setDescription] = useState(establishment.description)
  const [descriptionError, setDescriptionError] = useState<Boolean>(false)
  const [classification, setClassification] = useState(establishment.classification)
  const [monday, setMonday] = useState(establishment.hours.Monday)
  const [tuesday, setTuesday] = useState(establishment.hours.Tuesday)
  const [wednesday, setWednesday] = useState(establishment.hours.Wednesday)
  const [thursday, setThursday] = useState(establishment.hours.Thursday)
  const [friday, setFriday] = useState(establishment.hours.Friday)
  const [saturday, setSaturday] = useState(establishment.hours.Saturday)
  const [sunday, setSunday] = useState(establishment.hours.Sunday)
  
  const validateBusinessName = (businessName: string) => {
    return (businessName.length > 3)
  }
  const validateStreetAddress = (streetAddress: string) => {
    return (streetAddress.length > 3)
  }
  const validateCity = (city: string) => {
    return (city.length > 3)
  }
  const validateZipCode = (zipCode: string) => {
    return (zipCode.length > 4)
  }
  const validateLat = (lat: string) => {
    return (lat.length > 3)
  }
  const validateLon = (lon: string) => {
    return (lon.length > 3)
  }
  const validateUrl = (url: string) => {
    return true
  }
  const validatePhone = (phone: string) => {
    return true
  }
  const validateDescription = (description: string) => {
    return true
  }
  
  const inputs = [
    {
      title: "Name:",
      value: businessName,
      setter: setBusinessName,
      error: businessNameError,
      setError: setBusinessNameError,
      errorMessage: "You must enter a business name.",
      validate: validateBusinessName,
      inputType: "text"
    },
    {
      title: "Address:",
      value: streetAddress,
      setter: setStreetAddress,
      error: streetAddressError,
      setError: setStreetAddressError,
      errorMessage: "You must enter a street address.",
      validate: validateStreetAddress,
      inputType: "text"
    },
    {
      title: "City:",
      value: city,
      setter: setCity,
      error: cityError,
      setError: setCityError,
      errorMessage: "You must enter a city.",
      validate: validateCity,
      inputType: "text"
    },
    {
      title: "Zip Code:",
      value: zipCode,
      setter: setZipCode,
      error: zipCodeError,
      setError: setZipCodeError,
      errorMessage: "You must enter a zip code.",
      validate: validateZipCode,
      inputType: "text"
    },
    {
      title: "Lat:",
      value: lat,
      setter: setLat,
      error: latError,
      setError: setLatError,
      errorMessage: "You must enter a number.",
      validate: validateLat,
      inputType: "text"
    },
    {
      title: "Lon:",
      value: lon,
      setter: setLon,
      error: lonError,
      setError: setLonError,
      errorMessage: "You must enter a number.",
      validate: validateLon,
      inputType: "text"
    },
    {
      title: "URL:",
      value: url,
      setter: setUrl,
      error: urlError,
      setError: setUrlError,
      errorMessage: "You must enter a URL.",
      validate: validateUrl,
      inputType: "text"
    },
    {
      title: "Phone:",
      value: phone,
      setter: setPhone,
      error: phoneError,
      setError: setPhoneError,
      errorMessage: "You must enter a street address.",
      validate: validatePhone,
      inputType: "text"
    },
  ]
  const nullChecker = (val) => {
    if(val == null) {
      return ""
    } else {
      return val
    }
  }
  const updateEstab = () => {
    const hours = {
      Monday: monday,
      Tuesday: tuesday,
      Wednesday: wednesday,
      Thursday: thursday,
      Friday: friday,
      Saturday: saturday,
      Sunday: sunday
    }
  
    UpdateEstablishment({
      navigate,
      token,
      setToken,
      setTokenError,
      id: Number(establishment.id),
      businessName: businessName,
      streetAddress: streetAddress,
      city: city,
      state: usState,
      zipCode: zipCode,
      lat: lat,
      lon: lon,
      url: nullChecker(url),
      phone: nullChecker(phone),
      classification: classification, 
      description: nullChecker(description),
      hours: hours,
      renderCount: renderCount,
      setRenderCount: setRenderCount,
      setOpenUpdateModal: setOpenUpdateModal,
      setDatabaseAlert: setDatabaseAlert
    })
    .then(() => {
      console.log("Successful update")
      setTokenError(false)
    })
    .catch((error) => {
      console.error("Update failed", error)
    })
  }
  
  const [showDelete, setShowDelete] = useState(false)

  const deleteEstab = () => {
    DeleteEstablishment({
      navigate,
      id: Number(establishment.id),
      token,
      setToken,
      setTokenError,
      renderCount,
      setRenderCount,
      setOpenUpdateModal,
      setDatabaseAlert
    })
    .then(() => {
      console.log("Successful deletion")
      setTokenError(false)
    })
    .catch((error) => {
      console.error("Deletion failed", error)
    })
  }

  const openSplitter = (time: string) : string => {
    if (time.includes("–")) {
      var openTime = time.split("–")[0]
      return openTime.replace(/\s+/, " ")
    } else if (time.includes("Open")) {
      return "12 AM"
    }
    else {
      var openTime = "Closed"
      return openTime
    }
  }
  const closingSplitter = (time: string) : string => {
    if (time.includes("–")) {
      var openTime = time.split("–")[1]
      return openTime.replace(/\s+/, " ")
    } else if (time.includes("Open")) {
      return "12 AM"
    }
    else {
      var openTime = "Closed"
      return openTime
    }
  }
  
  const hoursContainerArray: Day[] = [
    {
      title: "Monday",
      setter: setMonday,
      value: monday
    },
    {
      title: "Tuesday",
      setter: setTuesday,
      value: tuesday 
    },
    {
      title: "Wednesday",
      setter: setWednesday,
      value: wednesday
    },
    {
      title: "Thursday",
      setter: setThursday,
      value: thursday
    },
    {
      title: "Friday",
      setter: setFriday,
      value: friday
    },
    {
      title: "Saturday",
      setter: setSaturday,
      value: saturday
    },
    {
      title: "Sunday",
      setter: setSunday,
      value: sunday
    }
  ]
  return (
    <form 
    className={styles.main}
      >
      {
        inputs.map((input, index) => 
          <UpdateInputField
            value={input.value}
            title={input.title}
            setter={input.setter}
            error={input.error}
            setError={input.setError}
            errorMessage={input.errorMessage}
            validate={input.validate}
            inputType={input.inputType}
            />
        )
      }

      <label id={"states-select"}>
        State:
        <select 
          name={"states"} 
          id={"states-select"} 
          defaultValue={usState}
          onChange={(event) => setUsState(event.target.value)}
        >
          {
            usStates.map((obj) =>
              <option value={obj.abbreviation}>
                {obj.name}
              </option>
            ) 
          }
        </select>
      </label>
      <label id={"classifications-select"}>
        Classification:
        <select 
          name={"classifications"}
          id={"classifications-select"}
          defaultValue={classifications.indexOf(establishment.classification)}
          onChange={(event) => setClassification(classifications[event.target.value])}
        >
          {
            classifications.map((classification, index) => 
              <option key={index} value={index}>
                {classification}
              </option>
            )
          }
        </select>
      </label>
      <label className={styles.hours}>
        {
          hoursContainerArray.map((day) => 
          <label>
            {day.title + ":"}
            <label id={"hours-select"}>
              Open:
              <select 
                name={"hours"}
                id={"hours-select"}
                onChange={(event) => {
                  var closingTime = day.value.split("–")[1]
                  day.setter(event.target.value + "–" + closingTime)
                }}
                defaultValue={openSplitter(day.value)}
              >
                {
                  hoursOptions.map((option, index) => 
                    <option key={index} value={option}>
                      {option}
                    </option>
                  )
                }
              </select>
              Close:
              <select 
                name={"hours"}
                id={"hours-select"}
                onChange={(event) => {
                  var openingTime = day.value.split("–")[0]
                  day.setter(openingTime + "–" + event.target.value)
                }}
                defaultValue={closingSplitter(day.value)}
              >
                {
                  hoursOptions.map((option, index) => 
                    <option key={index} value={option}>
                      {option}
                    </option>
                  )
                }
              </select>
            </label>
          </label>
          )
        }
      </label>
      <label>
        Description:
        <textarea
          defaultValue={establishment.description}
          rows={10}
          cols={75}
          maxLength={1600}
          name={establishment.name.replace(":", "")}
          onChange={(event) => setDescription(event.target.value)}
          onBlur={(event) => {
            if (validateDescription(event.target.value)) {
              setDescription(event.target.value)
            } else {
              setDescriptionError(true)
            }
          }}
          />
        {
          descriptionError ?
          <div className={styles.error}>
            {"Description must be 1600 characters or less."} 
          </div>
          : null
        }
      </label>
      <div className={styles.submissionButtons}>
        <NavButton
            title={"Delete Establishment"}
            clicker={()=>setShowDelete(true)}
          />
        <NavButton
          title={"Submit Update"}
          clicker={updateEstab}
        />
        <NavButton
            title={"Close"}
            clicker={()=>setOpenUpdateModal(false)}
          />
      </div>
      {
        showDelete ?
          <div className={styles.deleteContainer}>
            <div className={styles.deleteTitle}>
              Check Box to Delete
            </div>
            <input 
              type={"checkbox"}
              onChange={()=>deleteEstab()}      
            />
          </div>
        : null
      }
    </form>
  )
}