import React, { useState } from "react"
import styles from "../../styles/features/createForm.module.css"
import UpdateInputField from "./updateInputField.tsx"
import usStates from "../../helpers/data/usStates.js"
import classifications from "../../helpers/data/classifications.js"
import CreateEstablishment from "../../helpers/apiCalls/createEstablishment.js"
import NavButton from "./navButton.tsx"
import hoursOptions from "../../helpers/data/hoursOptions.js"

export default function CreateForm({
  navigate,
  token,
  setToken,
  setTokenError,
  renderCount,
  setRenderCount,
  setOpenCreateModal,
  databaseAlert,
  setDatabaseAlert
}) {
  const [businessName, setBusinessName] = useState("")
  const [businessNameError, setBusinessNameError] = useState<Boolean>(false)
  const [streetAddress, setStreetAddress] = useState("")
  const [streetAddressError, setStreetAddressError] = useState<Boolean>(false)
  const [city, setCity] = useState("")
  const [cityError, setCityError] = useState<Boolean>(false)
  const [usState, setUsState] = useState("AL")
  const [zipCode, setZipCode] = useState("")
  const [zipCodeError, setZipCodeError] = useState<Boolean>(false)
  const [lat, setLat] = useState("")
  const [latError, setLatError] = useState<Boolean>(false)
  const [lon, setLon] = useState("")
  const [lonError, setLonError] = useState<Boolean>(false)
  const [url, setUrl] = useState("")
  const [urlError, setUrlError] = useState<Boolean>(false)
  const [phone, setPhone] = useState("")
  const [phoneError, setPhoneError] = useState<Boolean>(false)
  const [description, setDescription] = useState("")
  const [descriptionError, setDescriptionError] = useState<Boolean>(false)
  const [classification, setClassification] = useState("bait_tackle")
  const [monday, setMonday] = useState("Closed-Closed")
  const [tuesday, setTuesday] = useState("Closed-Closed")
  const [wednesday, setWednesday] = useState("Closed-Closed")
  const [thursday, setThursday] = useState("Closed-Closed")
  const [friday, setFriday] = useState("Closed-Closed")
  const [saturday, setSaturday] = useState("Closed-Closed")
  const [sunday, setSunday] = useState("Closed-Closed")

  const hoursContainerArray = [
    {
      title: "Monday",
      setter: setMonday,
      value: monday
    },
    {
      title: "Tuesday",
      setter: setTuesday,
      value: tuesday 
    },
    {
      title: "Wednesday",
      setter: setWednesday,
      value: wednesday
    },
    {
      title: "Thursday",
      setter: setThursday,
      value: thursday
    },
    {
      title: "Friday",
      setter: setFriday,
      value: friday
    },
    {
      title: "Saturday",
      setter: setSaturday,
      value: saturday
    },
    {
      title: "Sunday",
      setter: setSunday,
      value: sunday
    }
  ]

  const validateBusinessName = (businessName: String) => {
    return (businessName.length > 3)
  }
  const validateStreetAddress = (streetAddress: String) => {
    return (streetAddress.length > 3)
  }
  const validateCity = (city: String) => {
    return (city.length > 3)
  }

  const validateZipCode = (zipCode: String) => {
    return (zipCode.length > 4)
  }
  const validateLat = (lat: String) => {
    return (lat.length > 3)
  }
  const validateLon = (lon: String) => {
    return (lon.length > 3)
  }
  const validateUrl = (url: String) => {
    return true
  }
  const validatePhone = (phone: String) => {
    return true
  }
  const validateDescription = (description: String) => {
    return true
  }

  const inputs = [
    {
      title: "Name:",
      value: businessName,
      setter: setBusinessName,
      error: businessNameError,
      setError: setBusinessNameError,
      errorMessage: "You must enter a business name.",
      validate: validateBusinessName,
      inputType: "text"
    },
    {
      title: "Address:",
      value: streetAddress,
      setter: setStreetAddress,
      error: streetAddressError,
      setError: setStreetAddressError,
      errorMessage: "You must enter a street address.",
      validate: validateStreetAddress,
      inputType: "text"
    },
    {
      title: "City:",
      value: city,
      setter: setCity,
      error: cityError,
      setError: setCityError,
      errorMessage: "You must enter a city.",
      validate: validateCity,
      inputType: "text"
    },
    {
      title: "Zip Code:",
      value: zipCode,
      setter: setZipCode,
      error: zipCodeError,
      setError: setZipCodeError,
      errorMessage: "You must enter a zip code.",
      validate: validateZipCode,
      inputType: "text"
    },
    {
      title: "Lat:",
      value: lat,
      setter: setLat,
      error: latError,
      setError: setLatError,
      errorMessage: "You must enter a number.",
      validate: validateLat,
      inputType: "text"
    },
    {
      title: "Lon:",
      value: lon,
      setter: setLon,
      error: lonError,
      setError: setLonError,
      errorMessage: "You must enter a number.",
      validate: validateLon,
      inputType: "text"
    },
    {
      title: "URL:",
      value: url,
      setter: setUrl,
      error: urlError,
      setError: setUrlError,
      errorMessage: "You must enter a URL.",
      validate: validateUrl,
      inputType: "text"
    },
    {
      title: "Phone:",
      value: phone,
      setter: setPhone,
      error: phoneError,
      setError: setPhoneError,
      errorMessage: "You must enter a street address.",
      validate: validatePhone,
      inputType: "text"
    },
  ]
    

  const createEstab = () => { 
    const hours = {
      Monday: monday,
      Tuesday: tuesday,
      Wednesday: wednesday,
      Thursday: thursday,
      Friday: friday,
      Saturday: saturday,
      Sunday: sunday
    }
    CreateEstablishment({
      navigate,
      name: businessName,
      streetAddress: streetAddress,
      city: city,
      state: usState,
      zipCode: zipCode,
      lat: lat,
      lon: lon,
      url: url,
      phone: phone,
      classification: classification,
      description: description,
      hours: hours,
      token,
      setToken,
      setTokenError,
      renderCount,
      setRenderCount,
      setOpenCreateModal,
      setDatabaseAlert
    })
  }

  const validateInputs: Boolean = (validateBusinessName(businessName) &&
    validateCity(city) &&
    validateStreetAddress(streetAddress) &&
    validateZipCode(zipCode) &&
    validateLat(lat) &&
    validateLon(lon) &&
    validateUrl(url) &&
    validatePhone(phone) &&
    validateDescription(description))

  return (
    <form className={styles.main}>
      {
        inputs.map((input, index) =>
          <UpdateInputField
            value={input.value}
            title={input.title}
            setter={input.setter}
            error={input.error}
            setError={input.setError}
            errorMessage={input.errorMessage}
            validate={input.validate}
            inputType={input.inputType}
          />
        )
      }
      <label id={"classifications-select"}>
        Classification:
        <select 
          name={"classifications"}
          id={"classifications-select"}
          defaultValue={classification}
          onChange={(event) => setClassification(classifications[event.target.value])}
        >
          {
            classifications.map((classification, index) => 
              <option key={index} value={index}>
                {classification}
              </option>
            )
          }
        </select>
      </label>
      <label id={"states-select"}>
        State:
        <select
          name={"states"}
          id={"states-select"}
          defaultValue={usState}
          onChange={(event) => setUsState(event.target.value)}
        >
          {
            usStates.map((obj) =>
              <option value={obj.abbreviation}>
                {obj.name}
              </option>
            ) 
          }
        </select>
      </label>
      <label className={styles.hours}>
        {
          hoursContainerArray.map((day) => 
          <label>
            {day.title + ":"}
            <label id={"hours-select"}>
              Open:
              <select 
                name={"hours"}
                id={"hours-select"}
                onChange={(event) => {
                  var closingTime = day.value.split("-")[1]
                  day.setter(event.target.value + "-" + closingTime)
                }}
              >
                {
                  hoursOptions.map((option, index) => 
                    <option key={index} value={option}>
                      {option}
                    </option>
                  )
                }
              </select>
              Close:
              <select 
                name={"hours"}
                id={"hours-select"}
                onChange={(event) => {
                  var openingTime = day.value.split("-")[0]
                  day.setter(openingTime + "-" + event.target.value)
                }}
              >
                {
                  hoursOptions.map((option, index) => 
                    <option key={index} value={option}>
                      {option}
                    </option>
                  )
                }
              </select>
            </label>
          </label>
          )
        }
      </label>
      <label>
        Description:
        <textarea
          defaultValue={description}
          rows={10}
          cols={75}
          maxLength={1600}
          onChange={(event) => setDescription(event.target.value)}
          onBlur={(event) => {
            if (validateDescription(event.target.value)) {
              setDescription(event.target.value)
            } else {
              setDescriptionError(true)
            }
          }}
        />
        {
          descriptionError ?
          <div className={styles.error}>
            {"Description must be 1600 characters or less"}
          </div>
          : null
        }
      </label>
      <div className={styles.submissionButtons}>  
        {
          validateInputs ?
            <NavButton
              title={"Create Establishment"}
              clicker={createEstab}
            />
          : null
        }
        <NavButton
          title={"Close"}
          clicker={()=>setOpenCreateModal(false)}
        />
      </div>
    </form>
  )
}