export default function FetchEstablishmentsByIdenticalLatAndLon({
  navigate,
  setApiError,
  setEstablishments,
  setToken,
  setTokenError,
  token, 
  // setEstablishments state Variable will need to be added to the dash.tsx file
  // so will setApiError
}){
  return fetch("https://api.shrimp-republic.com/graphql", {
      method: "POST",
      headers: {
          "Content-Type": "application/json"
      },
      body: JSON.stringify({
          query: 
          `query {
              establishmentsByIdenticalLatAndLon(
                  token: "${token}"
              ) {
                  id
                  name
                  streetAddress
                  city
                  state
                  zipCode
                  lat
                  lon
                  url
                  phone
                  hours {
                      Monday
                      Tuesday
                      Wednesday
                      Thursday
                      Friday
                      Saturday
                      Sunday
                  }
                  description
                  classification
                  reviewed
              }
          }`,
      })
  }, [])
  .then((response) => {
      if (response.ok) {
          setApiError(false)
          setTokenError(false)
          var json = response.json()
          console.log("No API errors during retrieval")
          return json
      } else {
          setApiError(true)
          console.log("An error occurred during API data retrieval")
      }
  })
  .then((json) => {
      var responseData = json.data 
      console.log("response data", responseData)
      var establishments = responseData.establishmentsByIdenticalLatAndLon
      
      setEstablishments(establishments)
      // May have to parse farther in this response to find the Establishments so i 
      // included a console log for you
  })
  .catch((error) => {
      setApiError(true)
      setToken("invalid")
      setTokenError(true)
      navigate("/")
      console.log("Api data retrieval error")
  })
}