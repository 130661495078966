import React, { useState } from "react"
import styles from "../../styles/features/updateInputField.module.css"

function UpdateInputField({
  value,
  error,
  errorMessage,
  setError,
  setter,
  validate,
  title,
  inputType
}) {

  const blur = (newValue: String) => {
    if (validate(newValue)) {
      setter(newValue)
    } else {
      setError(true)
    }
  }


  return (
    <div className={styles.main}>
      <label>
        {title}
        <input
          type={inputType}
          name={title.replace(":", "")}
          value={value}
          onChange={(event) => setter(event.target.value)}
          onBlur={(event) => 
            blur(event.target.value)
          } />
      </label>
        {
          error ?
          <div className={styles.error}>
            {errorMessage} 
          </div>
          : null
        }
    </div>
  )
}

export default UpdateInputField