import React from "react";
import styles from "../../styles/features/navButton.module.css"

function NavButton({clicker, title}) {
  return (
    <div className={styles.main} onClick={clicker}>
      {title}
    </div>
  )
}

export default NavButton