import React, { useState } from "react";
import styles from '../../styles/features/header.module.css'
import NavButton from "./navButton.tsx";
import { useNavigate } from "react-router-dom";
import Establishment from "../../types/establishment.tsx"

function Header({ 
  setOpenModal,
  fetchDuplicates,
  databaseAlert,
  setOpenCreateModal
 }) {
  const navigate = useNavigate()
  const [establishments, setEstablishments] = useState<Establishment[]>([]);

  return(
    <div className={styles.main}>
      <div className={styles.headerElement}>
        <div className={styles.title}>
          Establishments
        </div>
      </div>
      <div className={styles.headerElement}>
        <NavButton clicker={()=>setOpenModal(true)} title={"Select State"} />
      </div>
      <div className={styles.headerElement}>
        <NavButton clicker={fetchDuplicates} title={"Find Duplicates"}/>
      </div>
      <div className={styles.headerElement}>
        <NavButton clicker={()=>setOpenCreateModal(true)} title={"Create New"}/>
      </div>
      <div className={styles.headerElement}>
        <div className={styles.logout} onClick={()=>navigate("/")}>
          Logout
        </div>
        {
          databaseAlert !== "" ?
          <div className={styles.alert}>
            {databaseAlert}
          </div>
          : null
        }
      </div>
    </div>
  )
}
export default Header