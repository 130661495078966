export default function EstablishmentsByState({
  navigate, 
  usState, 
  setEstablishments,
  token, 
  setToken,
  setTokenError,
  setApiError 
}) {
  return fetch("http://localhost:4000/graphql", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    }, 
    body: JSON.stringify({
      query:
        `query {
          establishmentsFilteredByState(
            state: "${usState}",
            token: "${token}",
          ) {
            id
            name
            streetAddress
            city
            state
            zipCode
            lat
            lon
            url
            phone
            hours {
                Monday
                Tuesday
                Wednesday
                Thursday
                Friday
                Saturday
                Sunday
            }
            description
            classification
            reviewed  
          }
        }`,
    })
  }, [])
  .then((response) => {
    if (response.ok) {
      setApiError(false)
      setTokenError(false)
      var json = response.json()
      console.log("No API errors during retrieval")
      return json
    } else {
      setApiError(true)
      console.log("An error occurred during API data retrieval")
    }
  })
  .then((json) => {
    console.log("json response", json)
    var responseData = json.data
    console.log("response data", responseData)
    var establishments = responseData.establishmentsFilteredByState

    setEstablishments(establishments)
  })
  .catch((error) => {
    setApiError(true)
    setToken("invalid")
    setTokenError(true)
    navigate("/")
    console.log("Api data retrieval error")
  })
}