export default function Login({
    navigate,
    password, 
    setLoginError,
    setToken,
    setTokenError,
    email
}) {
    return fetch('https://api.shrimp-republic.com/graphql', {
        method: "POST", 
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            query: 
            `mutation {
              loginUser(
                input: {
                  email: "${email}",
                  password: "${password}"
              }) {
                token
                errors
              }
            }`,
        }),
    }, [])
    .then((response) => {
      if(response.ok) {
        setLoginError(false)
        setTokenError(false)
        var json = response.json()
        console.log("No error", json)
        return json
      } else {
        setLoginError(true)
        console.log("Login error big time")
      }
    })
    .then((json) => {
        var responseData = json.data
        console.log("Response data", responseData)

        var responseObject = responseData.loginUser
        console.log("Response obj", responseObject)
      

        var jwtToken = responseObject.token
        console.log("JWT Token", jwtToken)
        if(jwtToken !== "invalid") {
          setToken(jwtToken)
          navigate("/dash")
        } else {
          setToken(jwtToken)
          setLoginError(true)
        }
    })
    .catch(error => {
      setLoginError(true)
      console.log("Login error", error)
    })
}