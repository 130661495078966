import React, { useState } from "react";
import styles from "../../styles/features/modal.module.css"
import usStates from "../../helpers/data/usStates";
import EstablishmentsByState from "../../helpers/apiCalls/establishmentsByState";
import { useNavigate } from "react-router-dom";

function Modal({
    setOpenModal,
    setEstablishments,
    token,
    setToken,
    setTokenError,
    setApiError
}) {
    const navigate = useNavigate()
    const [selected, setSelected] = useState<number|null>(null)
    const select = (index: number) => {
        index === selected ? 
        setSelected(null)
        :
        setSelected(index)
    }
    const fetch = (index: number) => {
        var usState = usStates[index].abbreviation
        EstablishmentsByState({
            navigate, 
            usState, 
            setEstablishments,
            token, 
            setToken,
            setTokenError,
            setApiError
        })
        .then(() => {
            setTokenError(false)
            setOpenModal(false)
        })
    }
    return (
        <div className={styles.main}>
            { selected === null ?
                <div className={styles.title}>
                    Select State
                </div>
                : 
                <div className={styles.title}>
                    {usStates[selected].name}
                </div>
            }
            <div className={styles.states}>
                {
                    usStates.map((state, index)=> 
                        <div 
                            className={selected === index ? styles.selected : styles.state} 
                            onClick={()=>select(index)}
                            >
                            {state.name}
                        </div>
                    )
                }
            </div>
            <div className={styles.bottomButtons}>
                <div className={styles.close} onClick={()=>setOpenModal(false)}>
                    Close
                </div>
                {
                    selected !== null ?
                    <div className={styles.select} onClick={()=>fetch(selected)}>
                        Select
                    </div>
                    : null
                }
            </div>
        </div>
    )
}

export default Modal