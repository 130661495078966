import React from "react";
import styles from '../../styles/features/table.module.css'
import Establishment from "../../types/establishment";

function Table({
  establishments,
  openUpdateModal,
  setOpenUpdateModal,
  setEstablishment,
}) {
  const boolConverter = (reviewed: Boolean) : String => {
    if (reviewed) {
      return "true" 
    } else {
      return "false"
    }
  }

  const selectEstablishment = (establishment: Establishment) => {
    setEstablishment(establishment)
    setOpenUpdateModal(!openUpdateModal)
  }

  return (
    <div className={styles.main}>
      <table className={styles.table}>
        <tr>
          <th className={styles.idColumn}>ID</th>
          <th className={styles.nameColumn}>Name</th>
          <th className={styles.streetAddressColumn}>Address</th>
          <th className={styles.cityColumn}>City</th>
          <th className={styles.stateColumn}>State</th>
          <th className={styles.zipCodeColumn}>Zip</th>
          <th className={styles.latColumn}>Latitude</th>
          <th className={styles.lonColumn}>Longitude</th>
          <th className={styles.urlColumn}>URL</th>
          <th className={styles.phoneColumn}>Phone</th>
          <th className={styles.hoursColumn}>Hours</th>
          <th className={styles.descriptionColumn}>Description</th>
          <th className={styles.classificationColumn}>Class.</th>
          <th className={styles.reviewedColumn}>Reviewed</th>
        </tr>
        {
          establishments.map((establishment, key) => {
            return(
              <tr key={key}>
                <td className={styles.idColumn} onClick={() => selectEstablishment(establishment)}>{establishment.id}</td>
                <td className={styles.nameColumn}>{establishment.name}</td>
                <td className={styles.streetAddressColumn}>{establishment.streetAddress}</td>
                <td className={styles.cityColumn}>{establishment.city}</td>
                <td className={styles.stateColumn}>{establishment.state}</td>
                <td className={styles.zipCodeColumn}>{establishment.zipCode}</td>
                <td className={styles.latColumn}>{establishment.lat}</td>
                <td className={styles.lonColumn}>{establishment.lon}</td>
                <td className={styles.urlColumn}>{establishment.url}</td>
                <td className={styles.phoneColumn}>{establishment.phone}</td>
                <td className={styles.hoursColumn}>
                  <select name="hours" id="hours">
                  {
                    Object.keys(establishment.hours).map((hour) => {
                      return (
                        <option>{hour + ": " + establishment.hours[hour]}</option>
                      )
                    })
                  }
                  </select>
                </td>
                <td className={styles.descripitionColumn}>{establishment.description}</td>
                <td className={styles.classificationColumn}>{establishment.classification}</td>
                <td className={styles.reviewedColumn}>{boolConverter(establishment.reviewed)}</td>
              </tr>
            )
          })
        }
      </table>
    </div>
  )
}

export default Table