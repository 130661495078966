import React, { useState } from 'react'
import styles from '../../styles/screens/splash.module.css'
import { useNavigate } from 'react-router-dom'
import Login from '../../helpers/apiCalls/login.js'

function Splash({
  token,
  setToken,
  setTokenError,
  tokenError
}) {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [emailError, setEmailError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [loginError, setLoginError] = useState(false)
  const navigate = useNavigate()
  const dashNavigation = () => {
    Login({navigate, password, setLoginError, setToken, setTokenError, email})
  }
  const validateEmail = (email.length > 3 && email.length < 27)
  const validatePassword = (password.length > 3 && password.length < 17)
  const validPassword: Boolean = (!passwordError && validatePassword)
  const validEmail: Boolean = (!emailError && validateEmail)
  const checkInputs =  (
    validEmail &&
    validPassword
  )
  const blurEmail = () => {
    if(validateEmail) {
      setEmailError(false)
    } else {
      setEmailError(true)
    }
  }

  const blurPassword = () => {
    if(validatePassword) {
      setPasswordError(false)
    } else {
      setPasswordError(true)
    }
  }


  return (
    <div className={styles.main}>
      <div className={styles.box}>
        <text className={styles.header}>
          ShoreCasts Admin 
        </text>
        <div className={styles.loginBox}>
          <div className={styles.inputRow}>
            <div className={styles.inputTitle}>
              <text className={styles.title}>
                Email
              </text>
            </div>
            {
              emailError ? 
              <div className={styles.errorBox}>
                <text className={styles.errorText}>
                  Email must be greater than 3 chars
                </text>
              </div>
              : null
            }
            <div className={styles.inputField}>
              <input 
                className={emailError ? styles.inputError : styles.input}
                maxLength={26}
                onBlur={blurEmail}
                onChange={(e)=>setEmail(e.target.value)}
                type="text"
                value={email}
                />
            </div>
          </div>
          <div className={styles.inputRow}>
            <div className={styles.inputTitle}>
              <text className={styles.title}>
                Password
              </text>
            </div>
            {
              passwordError ? 
              <div className={styles.errorBox}>
                <text className={styles.errorText}>
                  Password must be greater than 3 chars
                </text>
              </div>
              : null
            }
            <div className={styles.inputField}>
              <input 
                className={passwordError ? styles.inputError : styles.input}
                maxLength={16}
                onBlur={blurPassword}
                onChange={(e)=>setPassword(e.target.value)}
                type="password"
                value={password}
                />
            </div>
          </div>
        </div>
        <div className={checkInputs ? styles.submit : styles.disableSubmit} onClick={()=>dashNavigation()}>
          SUBMIT
        </div>
        {
          loginError ? 
          <div className={styles.errorBox}>
            <text className={styles.errorText}>
              Incorrect user credentials
            </text>
          </div>
          : tokenError ?
          <div className={styles.errorBox}>
            <text className={styles.errorText}>
              Expired or invalid token
            </text>
          </div>
          : null
        } 
      </div>
    </div>
  )
}

export default Splash
