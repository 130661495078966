import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Splash from './components/screens/splash.tsx';
import Dash from './components/screens/dash.tsx';

function App() {
  const [token, setToken] = useState<String>("invalid")
  const [tokenError, setTokenError] = useState<Boolean>(false)

  return (
   <div className='App'>
    <Router>
      <Routes>
        <Route path="/" element={<Splash token={token} setToken={setToken} setTokenError={setTokenError} tokenError={tokenError}/>}/>
        <Route path="/dash" element={<Dash token={token} setToken={setToken} setTokenError={setTokenError}/>}/>
      </Routes>
    </Router>
   </div>
  );
}

export default App;
