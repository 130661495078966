import React, { useEffect, useState } from "react";
import Table from "./table.tsx";
import styles from "../../styles/features/body.module.css";

function Body({ 
  establishments,
  openUpdateModal,
  setOpenUpdateModal,
  setEstablishment,
 }) {

  return (
    <div className={styles.main}>
      <Table establishments={establishments} openUpdateModal={openUpdateModal} setOpenUpdateModal={setOpenUpdateModal} setEstablishment={setEstablishment} />
    </div>
  );
}

export default Body